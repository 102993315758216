import { getAuth } from "firebase/auth";
import { createWebHistory, createRouter } from "vue-router";
import { useStore } from 'vuex'
import { appBarStore } from "@/store/AppBarStore";
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import Pending from '@/views/Pending.vue';
import Adventure from '@/views/adventure/Adventure.vue';
import AdventureList from '@/views/adventure/AdventureList.vue';
import AdventureCreate from '@/views/adventure/AdventureCreate.vue';
import AdventureJoin from '@/views/adventure/AdventureJoin.vue';
import UserProfile from '@/views/user/UserProfile.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pending",
    name: "Pending",
    component: Pending,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/reset",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/adventure/:id?",
    name: "Adventure",
    component: Adventure,
    meta: {
      requiresAuth: true,
      requiresAlpha: true,
    }
  },
  {
    path: "/adventure/new",
    name: "AdventureCreate",
    component: AdventureCreate,
    meta: {
      requiresAuth: true,
      requiresAlpha: true,
    }
  },
  {
    path: "/adventure/join/:id?",
    name: "AdventureJoin",
    component: AdventureJoin,
    meta: {
      requiresAuth: true,
      requiresAlpha: true,
    }
  },
  {
    path: "/adventure/archives",
    name: "AdventureList",
    component: AdventureList,
    meta: {
      requiresAuth: true,
      requiresAlpha: true,
    }
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresAlpha: true,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const auth = getAuth()
  const store = useStore()
  const currentUser = auth.currentUser

  appBarStore.value.clear();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    return { name: 'Login'}
  }

  const requiresAlpha = to.matched.some(record => record.meta.requiresAlpha)
  if (requiresAlpha && !store.state.isAlphaUser) {
    return { name: 'Pending'}
  }
})

export default router;
