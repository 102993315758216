<template>
  <div class="animation-container mt-16">
    <transition-group name="list" tag="div" class="list">
      <div v-for="(item, index) in visibleItems" :key="item" class="list-item" :class="{ 'fade-out': index === 0, 'fade-in': index === visibleItems.length - 1 }">
        {{ item }}
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { ref, watch, onUnmounted, computed } from 'vue'
import { ALL_ITEMS } from './AdventureCreateProcessingMessages.js'

const CACHE_SIZE = 3
const INTERVAL_TIME = 4000
const ITEM_REMOVAL_DELAY = 2000

const props = defineProps({
  isAnimationRunning: {
    type: Boolean,
    default: false,
  },
})

let cache = []
const allItems = ALL_ITEMS
const items = ref([getItem()])
let currentIndex = 2
let intervalId = null

function getItem() {
  let item
  do {
    item = allItems[Math.floor(Math.random() * allItems.length)]
  } while (cache.includes(item))
  
  cache.push(item)
  if (cache.length > CACHE_SIZE) cache.shift()

  return item
}

watch(() => props.isAnimationRunning, (newVal) => {
  if (newVal) {
    intervalId = setInterval(() => {
      items.value.push(getItem())
      currentIndex = (currentIndex + 1) % allItems.length

      setTimeout(() => {
        items.value.shift()
      }, ITEM_REMOVAL_DELAY)
    }, INTERVAL_TIME)
  } else {
    clearInterval(intervalId)
  }
})

onUnmounted(() => {
  clearInterval(intervalId)
})

const visibleItems = computed(() => items.value.slice(-3))
</script>

<style scoped>
.animation-container {
  overflow: hidden;
  padding: 50px 0;
}

.list {
  margin: -50px 0;
}

.list-item {
  transition: all 2s ease;
}

.list-item.fade-out {
  animation: fade-out 2s forwards;
}

.list-item.fade-in {
  animation: fade-in 2s forwards;
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>