<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card>
      <v-card-title class="headline">Invite Some Friends</v-card-title>
      <v-card-text>Share this code to invite friends to your adventure.
        <v-row class="mt-3">
          <v-col cols="9">
            <v-btn
              block
              color="primary"
              :disabled="true"
              :loading="loading"
              class="text-case-sensitive code-font"
            >
              {{ invitationCode }}
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              block
              color="primary"
              @click="copyCode"
            >
              <v-icon>{{ copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
              <v-tooltip activator="parent" location="top">Copy code</v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <div class="text-caption text-center mt-2">
          This code will expire in {{ expiresInMinutes }} minutes
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed, defineExpose } from 'vue';
import { useStore } from 'vuex'
import { api } from '@/utils/api.js'

const store = useStore()
const dialog = ref(false);
const copied = ref(false);
const loading = ref(false);
let invitationCode = ref('');
let expiresInMinutes = ref('30');

const cachedCode = computed(() => store.state.invitationCodes[store.state.adventureId]);
const checkAndFetchCode = () => {
  let isCachedCodeExpired = true;
  if (cachedCode.value && cachedCode.value.expiresAt) {
    isCachedCodeExpired = cachedCode.value.expiresAt < new Date();
  }
  if (!cachedCode.value || !cachedCode.value.code ||  isCachedCodeExpired) {
    invitationCode.value = '';
    loading.value = true;
    api.adventure.invite(store.state.adventureId)
        .then((response) => {
          invitationCode.value = response.data.invitationCode;
          expiresInMinutes.value = response.data.expiresInMinutes;

          const expiresAt = new Date();
          expiresAt.setMinutes(expiresAt.getMinutes() + response.data.expiresInMinutes - 1);
          store.dispatch('setInvitationCode', {
            adventureId: store.state.adventureId,
            code: response.data.invitationCode,
            expiresInMinutes: response.data.expiresInMinutes,
            expiresAt
          });
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false;
        });
  } else {
    invitationCode.value = cachedCode.value.code;
    expiresInMinutes.value = cachedCode.value.expiresInMinutes;
  }
};

const copyCode = () => {
  navigator.clipboard.writeText(invitationCode.value);
  copied.value = true;
};

const openDialog = () => {
  dialog.value = true;
  checkAndFetchCode();
};

defineExpose({ openDialog });
</script>

<style scoped>
.text-case-sensitive {
  text-transform: none !important;
}

.code-font {
  font-family: 'Courier New', monospace;
  font-weight: bold;
  font-size: 20px;
}
</style>