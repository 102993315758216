<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import ProgressSheet from '@/components/ProgressSheet.vue'
import AdventureJoinInvitation from '@/components/AdventureJoinInvitation.vue'
import AdventureJoinPreview from '@/components/AdventureJoinPreview.vue'
import AdventureJoinCharacter from '@/components/AdventureJoinCharacter.vue'
import useAdventurePreview from '@/composables/useAdventurePreview'
import useAdventureJoin from '@/composables/useAdventureJoin'

const store = useStore()
const router = useRouter()
const route = useRoute()
const activeStep = ref("1")
const isLoading = ref(false)
const errorMessage = ref('')
const invitationCode = ref()
const characterName = ref()

// TODO: action does not work with current routing setup
appBarStore.value.update(
  'Adventure',
  [
    {
      label: store.adventureId ? 'Active Adventure' : null,
      icon: store.adventureId ? 'mdi-heart-half-full' : null,
      routePath: store.adventureId ? `/adventure/${store.adventureID}` : null
    }
  ]
)

invitationCode.value = route.params.id

const previousStep = () => {
  let step = Number(activeStep.value)
  if (step > 1) {
    step--
  }
  activeStep.value = step.toString()
  errorMessage.value = ''
}

const stepperActionsDisabled = computed(() => {
  if (isLoading.value) {
    return true
  }

  let step = Number(activeStep.value)
  switch(step) {
    case 1: return 'prev'
    default: return false
  }
})
const nextButtonText = computed(() => {
  let step = Number(activeStep.value)
  switch(step) {
    case 3: return 'Join'
    default: return 'Next'
  }
})
const nextStep = () => {
  errorMessage.value = ''
  let step = Number(activeStep.value)
  switch (step) {
    case 1:
      if (!invitationCode.value) {
        errorMessage.value = "Invitation code cannot be empty"
        return
      }
      handlePreview()
      break
    case 3:
      if (!characterName.value) {
        errorMessage.value = "Character name cannot be empty"
        return
      }
      handleJoin(characterName.value)
      break
    default:
      step++
      activeStep.value = step.toString()
  }
}

const adventurePreviewData = computed(() => store.state.adventurePreviewData[invitationCode.value])
const { previewAdventure } = useAdventurePreview()
const handlePreview = async () => {
  isLoading.value = true
  previewAdventure(invitationCode.value)
    .then(() => activeStep.value = "2")
    .catch((error) => errorMessage.value = error.displayMessage)
    .finally(() => isLoading.value = false)
}

const { joinAdventure } = useAdventureJoin()
const handleJoin = async (characterName) => {
  isLoading.value = true
  joinAdventure(invitationCode.value, characterName)
    .then((adventureId) => {
      router.push({
        name: 'Adventure',
        params: {
          id: adventureId
        }
      })
    })
    .catch((error) => errorMessage.value = error.displayMessage)
    .finally(() => isLoading.value = false)
}

</script>

<template>
  <v-card max-width="800" class="mx-auto mt-4 card-primary">
    <h1>Join Some Friends</h1><br>
    <v-stepper v-model="activeStep">
      <v-stepper-header>
        <v-stepper-item
          title="Invitation Code"
          value="1"
          :complete="activeStep > 0"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Preview Adventure"
          value="2"
          :complete="activeStep > 1"
        ></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          title="Join Friends"
          value="3"
          :complete="activeStep > 2"
        ></v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>

        <v-stepper-window-item value="1">
          <ProgressSheet :isLoading="isLoading">
            <AdventureJoinInvitation
                v-model:invitationCode="invitationCode"
                :errorMessage="errorMessage"
                :isDisabled="isLoading"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="2">
          <ProgressSheet>
            <AdventureJoinPreview
              v-if="adventurePreviewData" 
              :adventurePreviewData="adventurePreviewData"
            />
          </ProgressSheet>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <ProgressSheet :isLoading="isLoading">
            <v-sheet width="300" class="mx-auto mt-4">
              <AdventureJoinCharacter 
                v-model:characterName="characterName"
                :errorMessage="errorMessage"
                :isDisabled="isLoading"
              />
            </v-sheet>
          </ProgressSheet>
        </v-stepper-window-item>

      </v-stepper-window>

      <v-stepper-actions
        @click:prev="previousStep"
        @click:next="nextStep"
        :disabled=stepperActionsDisabled
        :next-text="nextButtonText"
      ></v-stepper-actions>

    </v-stepper>
  </v-card>
</template>