import { ref } from 'vue';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

export function useFirestoreChannel(channelId) {
  const document = ref(null);
  const error = ref(null);

  const db = getFirestore();
  let docRef = doc(db, 'channels', channelId);

  const stop = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      document.value = { ...doc.data(), id: doc.id };
      error.value = null;
    } else {
      console.log("No such document!");
    }
  }, (err) => {
    console.log("Error fetching document:", err);
    error.value = err;
  });

  return { document, error, stop };
}
