import { useStore } from 'vuex'
import { api } from '@/utils/api.js'

export default function useAdventureJoin() {
  const store = useStore()

  const joinAdventure = async (invitationCode, characterName) => {
    return api.adventure.join(invitationCode, characterName)
      .then((response) => {
        console.log(response.data)
        const adventureId = response.data.adventureId
        store.dispatch('setAdventureId', adventureId)
        store.dispatch('setAdventureName', characterName)
        return adventureId
      })
      .catch((error) => {
        console.log(error)
        switch (error.response.status) {
          case 410:
            error.displayMessage = "Invitation code has expired"
            break
          case 409:
            error.displayMessage = "Invitation code has already been used"
            break
          default:
            error.displayMessage = "Invalid invitation code"
        }
        throw error
      })
  }

  return {
    joinAdventure
  }
}