export const ALL_ITEMS = [
  'Brewing up some trouble!',
  'Crafting dastardly characters',
  'Worldbuilding in progress...',
  'Just a few more details...',
  'Polishing adventures for maximum excitement!',
  'Feeling the creative flow!',
  'Inspiration has struck, expect the unexpected!!!',
  'Taking a break to sharpen my DMing skills!',
  'Just a few more tweaks...',
  'Making characters cozy',
  'Epic encounters? Check!',
  'Get ready to test your skills in combat and beyond!',
  'Music selection is key!',
  'Cool map.. done!',
  'Feeling a little peckish...',
  'Double-checking the rules...',
  'The countdown is on!',
  'Deep breaths, everyone!',
  'Unearthing some hidden lore',
  'Thinking like a villain...', 
  'Research rabbit hole alert!',
  'Playtesting loot',
  'Worldbuilding tangent',
  'Preparing handouts',
  'Building suspense... Mwahahaha!',
  'Just.. a.. short.. nap..',
  'Gathering digital dice',
  'Almost there, adventurers!',
  'Feeling nostalgic...',
  'Prepping traps',
  'Just a few more NPCs...',
  'Getting into character',
  'Checking the weather...',
  'Polishing up the plot',
  'Just a few more details...',
  'Getting into the zone',
  'Feeling the creative flow!',
  'Feeling the pressure...',
  'Gathering spell cards',
  'Gathering rumors from shady sources',
  'Checking the calendar...',
  'NPCs warming up their vocal cords',
  'Adding sprinkles of chaos!',
  'Just summoning a talking squirrel...',
  'NPCs are arguing over socks. Back shortly...',
  'Dice are rolling...destiny awaits!',
  'Calibrating giggle meter',
  'Dragons require a surprising amount of paperwork',
  'Mayhem marinating. Almost ready to serve.',
  'Sharpening puns',
  'Preparing traps...Ouch!',
  'Preparing loot: bananas check!',
  'Brushing up on goblin etiquette',
  'Set the dungeon on fire. Oops.',
  'The NPCs are revolting! Send help... and snacks',
  'Adding glitter...',
  'Calibrating the random encounter machine. Brace yourselves.',
  'Orcs are surprisingly picky eaters. Who knew?',
  'Discovered a secret society of squirrels. Fascinating!',
  'Almost done... really',
  'Tea anyone?',
];

