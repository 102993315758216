<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/utils/api.js'
import { useFirestoreChannelIsTypingUpdater } from '@/composables/useFirestoreChannelIsTypingUpdater.js'

const props = defineProps({
  isBotResponding: Boolean
})

const store = useStore()
const newMessage = ref('')
const inputField = ref()
const emit = defineEmits(['sendMessage', 'updateIsBotResponding'])
const userId = store.state.user
const channelId = store.state.adventureId
const { startTyping, stopTyping } = useFirestoreChannelIsTypingUpdater(channelId, userId)

const TYPING_REFRESH_DURATION_MILLIS = 5000;
const TYPING_TIMEOUT_BUFFER_MILLIS = 50;

let typingTimeout
let lastTypingAt

const handleSendMessage = async () => {
  const userMessage = newMessage.value;
  emit('sendMessage', userMessage)
  newMessage.value = '';

  if (props.isBotResponding) {
    return;
  }
  emit('updateIsBotResponding', true);

  try {
    await api.adventure.prompt(store.state.adventureId, userMessage)
  } catch (error) {
    console.error(error.message);
  } finally {
    emit('updateIsBotResponding', false);
  }
}

const handleInput = () => {
  clearTimeout(typingTimeout)
  typingTimeout = setTimeout(stopTyping, TYPING_REFRESH_DURATION_MILLIS + TYPING_TIMEOUT_BUFFER_MILLIS)

  const now = Date.now()
  if (!lastTypingAt || now - lastTypingAt >= TYPING_REFRESH_DURATION_MILLIS) {
    lastTypingAt = now
    startTyping()
  }
}

onMounted(() => {
  nextTick(() => {
    inputField.value.focus()
  })
})

onUnmounted(() => {
  clearTimeout(typingTimeout)
  stopTyping()
})

const vLimitLength = {
  eventHandler(el, binding) {
    if (el.value.length > binding.value) {
      el.value = el.value.slice(0, binding.value)
    }
  },
  beforeMount(el, binding) {
    el.addEventListener('input', (event) => vLimitLength.eventHandler(event.target, binding))
  },
  beforeUnmount(el, binding) {
    el.removeEventListener('input', (event) => vLimitLength.eventHandler(event.target, binding))
  }
}
</script>

<template>
  <input 
    class="input"
    type="text"
    autocapitalize="off"
    autocomplete="off"
    autocorrect="off"
    spellcheck="false"
    placeholder="Say something"
    ref="inputField"
    v-model="newMessage"
    v-limit-length="1000"
    @keyup.enter="handleSendMessage"
    @input="handleInput"
  >
</template>

<style scoped>
.input {
  border: 1px solid #a5a5a5;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}
</style>