<script setup>
import { defineProps, ref } from 'vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  adventurePreviewData: {
    type: Object,
    required: true
  }
})
const previewListGroups = ref([])

const generateMemberNameString = (memberId, isDisplayOnly = false) => {
  const displayName = props.adventurePreviewData.displayNames?.[memberId]
  const playerName = props.adventurePreviewData.playerNames?.[memberId]

  let memberName = ''
  if (playerName && !isDisplayOnly) {
    memberName = playerName
  }
  if (displayName) {
    if (memberName) {
      memberName += ' - '
    }
    memberName += displayName
  } 
  return memberName || 'Unknown...'
}

const populatePreviewList = () => {
  if (!props.adventurePreviewData) {
    return
  }

  const createdBy = generateMemberNameString(props.adventurePreviewData.createdBy, true)
  previewListGroups.value.push({
    name: 'Created By:',
    members: [createdBy]
  })

  const memberNames = []
  for (const memberId of props.adventurePreviewData.members) {
    const memberName = generateMemberNameString(memberId)
    memberNames.push(memberName)
  }
  if (memberNames.length > 0) {
    previewListGroups.value.push({
      name: 'Members:',
      members: memberNames
    })
  }
}
populatePreviewList()
</script>

<template>
  <v-sheet
    class="mx-auto mt-4"
    max-width="800"
  >
    <h3 v-if="adventurePreviewData">{{ adventurePreviewData.name }}</h3>
    <v-list lines="one" class="text-center">

        <v-list-item
          v-for="(group, groupIndex) in previewListGroups"
          :key="'group-' + groupIndex"
        >
          <h4>{{ group.name }}</h4>

          <v-list>
            <v-list-item
              v-for="(memberName, memberIndex) in group.members"
              :key="'member-' + memberIndex"
              :title="memberName"
            >
            </v-list-item>
          </v-list>

        </v-list-item>
    </v-list>
  </v-sheet>
</template>