<script setup>
import { toRefs } from 'vue'
// import { ref, toRefs } from 'vue'
// import { api } from '@/utils/api.js'

const props = defineProps({
  message: Object,
  displayNames: Object,
  playerNames: Object
})

const { message, playerNames } = toRefs(props);
// const feedback = ref('')

const generateSentByString = () => {
  const senderId = message.value.sentBy

  let sentByString = ''
  if (playerNames.value && playerNames.value[senderId]) {
    sentByString = playerNames.value[senderId]
  }
  return sentByString || 'Unknown...'
}

// const handleThumbsUp = async () => {
//   feedback.value = 'up';
//   try {
//     await api.adventure.feedback(props.message.feedbackId, 1)
//   } catch (error) {
//     console.error(error.message);
//   }
// }

// const handleThumbsDown = async () => {
//   feedback.value = 'down';
//   try {
//     await api.adventure.feedback(props.message.feedbackId, 0)
//   } catch (error) {
//     console.error(error.message);
//   }
// }

// const isRejected = (feedbackValue) => {
//   return feedback.value === feedbackValue
// }
</script>

<template>
  <div :class="`message ${message.origin}`">
    <span class="message-content">{{ message.content }}</span>
    <div v-if="message.origin === 'human'" class="sent-by">
      <p>{{ generateSentByString() }}</p>
    </div>
    <!-- <div v-if="message.origin === 'ai' && message.feedbackId" class="feedback-buttons">
      <div class="feedback-prompt">
        Our adventure bot is still learning and improving. Please let us know how it did with a thumbs up or down.
      </div>
      <button 
        @click="handleThumbsUp"
        :class="{ rejected: isRejected('down')}"
        title="Click thumbs up if the response was great"
      >👍</button>
      <button
        @click="handleThumbsDown"
        :class="{ rejected: isRejected('up')}"
        title="Click thumbs down if the response could have been better"
      >👎</button>
    </div> -->
  </div>
</template>

<style scoped>
.message {
  margin: 10px 0;
}

.message.human {
  float: right;
  text-align: right;
  background-color: rgb(var(--v-theme-accent-light));
  padding: 10px;
  border-radius: 15px;
  max-width: 80%;
}

.message.ai {
  float: left; /* Align the bubble to the left */
  text-align: left;
}

.message-content {
  white-space: pre-wrap;
}

.sent-by {
  font-size: 0.8em;
  color: #888;
}

.feedback-prompt {
  font-size: 0.8em;
  color: #888;
  font-weight: 600;
  margin-top: 15px;
}

.feedback-buttons button {
  margin-right: 5px;
}

.rejected {
  opacity: 0.5;
}
</style>