<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="mb-4 px-8" v-if="!isLoggedIn">
      <p>
        <router-link to="/login">Login</router-link> or <router-link to="/register"> Register </router-link> to start   your adventure! 
      </p>
    </div>
  </div>

  <div class="pa-4 mt-12">
    <img class="title-image" :src="require('@/assets/rawr.png')" alt="AdventuRAWR.ai">
  </div>

  <div class="mb-4 px-8" v-if="isLoggedIn">
      <v-btn
        class="mt-8 me-1"
        color="primary"
        rounded="xl"
        size="x-large"
        flat
        :to="{ name: 'AdventureCreate' }"
      > Start a New Adventure </v-btn>
  </div>
</template>


<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js';

const store = useStore()
const isLoggedIn = computed(() => store.state.token !== null)

appBarStore.value.setTitle('Home');

watch(isLoggedIn, (newValue) => {
  if (!newValue) {
    appBarStore.value.setTitle('Home');
    appBarStore.value.update('Home', []);
  }
});

</script>

<style scoped>

/* CSS for mobile screens */
@media screen and (max-width: 768px) {
  .title-image {
    width: 100%;
    height: auto;
    max-height: 60vh;
  } 
}
</style>
