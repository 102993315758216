<script setup>
import { onMounted, onUnmounted, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useFirestoreMessages } from '@/composables/useFirestoreMessages.js'
import { useFirestoreChannel } from '@/composables/useFirestoreChannel.js'
import AdventureChatMessageList from '@/components/AdventureChatMessageList.vue'
import AdventureChatTypingIndicator from '@/components/AdventureChatTypingIndicator.vue'
import AdventureChatInputField from '@/components/AdventureChatInputField.vue'

const HEADER_HEIGHT = 64
const store = useStore()
const isBotResponding = ref(false)
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
let targetHeight = window.innerHeight
let calcHeight = ref(window.innerHeight)


let messages, stopMessages, channel, stopChannel
if (store.state.adventureId) {
  ({ documents: messages, stop: stopMessages } = useFirestoreMessages(store.state.adventureId));
  ({ document: channel, stop: stopChannel } = useFirestoreChannel(store.state.adventureId))
}

const onResize = () => {
  const visibleHeight = Math.min(window.innerHeight, targetHeight) 
  calcHeight.value = visibleHeight - HEADER_HEIGHT
}

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize)
  // https://stackoverflow.com/questions/47841986/detecting-the-opening-or-closing-of-a-virtual-keyboard-on-a-touchscreen-device#47842110
  if ('visualViewport' in window) {
    window.visualViewport.addEventListener('resize', function (event) {
      targetHeight = event.target.height
      onResize()
      window.scrollTo(0, 0)
      document.body.scrollTop = 0
    })
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
  if ('visualViewport' in window) {
    window.visualViewport.removeEventListener('resize', onResize)
  }
  stopMessages()
  stopChannel()
})

let containerStyle = computed(() => {
  return isIOS ? { height: calcHeight.value + 'px' } : {}
})

watch(() => store.state.adventureId, (newAdventureId) => {
  if (newAdventureId) {
    stopMessages()
    const { documents: newMessages, stop: stopNewMessages } = useFirestoreMessages(newAdventureId)
    stopMessages = stopNewMessages
    watch(() => newMessages.value, (newMessagesValue) => {
      messages.value = newMessagesValue
    })
  }
})

let displayNames = ref({});
let playerNames = ref({});
watch(channel, (newChannel) => {
  if (newChannel.aiRespondingAt) {
    isBotResponding.value = true
  } else {
    isBotResponding.value = false
  }
  displayNames.value = newChannel.displayNames
  playerNames.value = newChannel.playerNames
})

const handleSendMessage = (userMessage) => {
  messages.value = [...messages.value, {
    content: userMessage,
    origin: 'human',
    sentBy: store.state.user
  }]
}

const handleIsBotRespondingUpdate = (value) => {
  isBotResponding.value = value
}
</script>

<template>
  <v-container :style="containerStyle">
    <div class="adventure-chat">
      <AdventureChatMessageList 
        :messages="messages"
        :displayNames="displayNames"
        :playerNames="playerNames"
        :isBotResponding="isBotResponding"
        class="flex-grow-1 overflow-auto"
      />
      <AdventureChatTypingIndicator
        :displayNames="displayNames"
        class="text-start"
        />
      <AdventureChatInputField
        :isBotResponding="isBotResponding"
        @sendMessage="handleSendMessage"
        @updateIsBotResponding="handleIsBotRespondingUpdate"
      />
    </div>
  </v-container>
</template>

<style scoped>
.v-container {
  height: calc(100vh - 64px); /* This will apply on mobile devices */
}

@media (min-width: 600px) {
  .v-container {
    margin-top: -20px;
  }
}

.adventure-chat {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background: #fafafa;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
</style>
