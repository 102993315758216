<template>
  <v-app-bar app color="secondary" class="px-sm-10" dark>
    <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <div class="d-flex align-center"><h3> {{appBarStore.title}} </h3></div>

    <v-spacer></v-spacer>

    <div v-for="(item, i) in appBarStore.actionItems" :key="i">
      <v-btn
        :to="item.routeName ? { name: item.routeName } : item.routePath"
        @click.prevent="handleAppBarClick(item)"
        text
      >
        <span class="d-none d-sm-block mr-2">{{item.label}}</span>
        <v-icon>{{item.icon}}</v-icon>
      </v-btn>
    </div>
  </v-app-bar>

  <v-navigation-drawer
    v-model="drawer"
    class="text-left"
    color="accent"
    temporary>
    <v-list nav>
      <template v-for="group in items" :key="group.group">
        <v-list-subheader 
          v-if="group.group"
          class="text-text-light"
        >{{ group.group }}</v-list-subheader>
        <v-list-item
          v-for="item in group.items"
          :key="item.title"
          :to="item.routeName ? { name: item.routeName } : null"
          :disabled="item.disabled"
          :prepend-icon="item.icon || null"
          :title="item.title"
          @click="handleNavItemClick(item.action)"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { getAuth, signOut } from 'firebase/auth'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'

const router = useRouter()
const store = useStore()

const handleAppBarClick = (actionItem) => {
  if (actionItem.handleClick) {
    actionItem.handleClick()
  }
}

const isLoggedIn = computed(() => store.state.user ? true : false)
const isAlphaUser = computed(() => store.state.isAlphaUser)
const drawer = ref(false)
const items = computed(() => {
  const computedItems = [
    {
      items: [
        { title: 'Home', routeName: 'Home', icon: 'mdi-home' },
      ],
    },
  ];

  if (isAlphaUser.value) {
    computedItems.push({
      group: 'Adventure',
      items: [
        { title: 'Current Quest', routeName: 'Adventure', icon: 'mdi-compass' },
        { title: 'Start New Adventure', routeName: 'AdventureCreate', icon: 'mdi-map-plus' },
        { title: 'My Adventures', routeName: 'AdventureList', icon: 'mdi-book-open-variant' },
        { title: 'Join Multiplayer Game', routeName: 'AdventureJoin', icon: 'mdi-account-group' },
      ],
    });
  }

  if (isLoggedIn.value) {
    const userItems = [];
    if (isAlphaUser.value) {
      userItems.push({ title: 'Profile', routeName: 'UserProfile', icon: 'mdi-account-circle' });
    }
    userItems.push({ title: 'Logout', action: 'logout', icon: 'mdi-logout' });
    computedItems.push({
      group: 'User',
      items: userItems,
    });
  } else {
    computedItems.push({
      group: 'User',
      items: [
        { title: 'Register', routeName: 'Register', icon: 'mdi-list-box-outline' },
        { title: 'Login', routeName: 'Login', icon: 'mdi-login' },
      ],
    });
  }

  return computedItems;
});

const handleNavItemClick = (action) => {
  if (action === 'logout') {
    handleSignOut();
  }
  drawer.value = false
}

const handleSignOut = () => {
  signOut(getAuth())
  store.dispatch('setUser', null)
  store.dispatch('setToken', null)
  store.dispatch('setAdventureId', null)
  store.dispatch('setIsAlphaUser', false)
  router.push('/')
}
</script>
