<script setup>
import { ref, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useFirestoreChannelIsTypingWatcher } from '@/composables/useFirestoreChannelIsTypingWatcher'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  displayNames: Object,
})

const store = useStore()
const isTypingMessage = ref('')
const channelId = store.state.adventureId
const { isTyping, stop } = useFirestoreChannelIsTypingWatcher(channelId)

const TYPING_IGNORE_DURATION_MILLIS = 10000;

// method to create the isTypingMessage based on a list of memberIds
const udpateIsTypingMessage = (whoIsTyping) => {
  if (!props.displayNames) {
    console.error('udpateIsTypingMessage - props.displayNames is not defined')
    return
  }

  // filter out any members that don't have a display name
  whoIsTyping = whoIsTyping.filter(memberId => props.displayNames[memberId] !== undefined);

  let newIsTypingMessage = ''
  switch (whoIsTyping.length) {
    case 0:
      newIsTypingMessage = ''
      break
    case 1:
      newIsTypingMessage = `${props.displayNames[whoIsTyping[0]]} is typing`
      break
    case 2:
      newIsTypingMessage = `${props.displayNames[whoIsTyping[0]]} and ${props.displayNames[whoIsTyping[1]]} are typing`
      break
    default:
      newIsTypingMessage = `${props.displayNames[whoIsTyping[0]]}, ${props.displayNames[whoIsTyping[1]]} and ${whoIsTyping.length - 2} others are typing`
      break
  }
  isTypingMessage.value = newIsTypingMessage
}

watch(isTyping, (newIsTyping) => {
  if (newIsTyping) {
    let whoIsTyping = []
    for (let memberId in newIsTyping) {
      const isTypingData = newIsTyping[memberId]
      const isTypingAt = isTypingData?.isTypingAt
      const isTyping = isTypingData?.isTyping

      if (!isTyping) {
        continue
      }

      if (isTypingAt && isTypingAt.toMillis() < Date.now() - TYPING_IGNORE_DURATION_MILLIS) {
        continue
      }

      if (memberId === store.state.user) {
        continue
      }

      whoIsTyping.push(memberId)
    }
    udpateIsTypingMessage(whoIsTyping)
  }
})

onUnmounted(() => {
  stop()
})
</script>

<template>
  <div v-if="isTypingMessage" class="typing-indicator">
    {{ isTypingMessage }}<span class="dot ms-1">.</span><span class="dot">.</span><span class="dot">.</span>
  </div>
</template>

<style scoped>
.typing-indicator {
  color: #888;
  font-style: italic;
}

@keyframes bounce {
  0%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-2.5px); }
}

.dot {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  animation: bounce 1.4s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
</style>