<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Reset Your Password</h1>
    <v-sheet width="300" class="mx-auto">
      <v-form @submit.prevent="sendResetEmail">
        <v-text-field
          v-model="email"
          label="Email"
          required
        ></v-text-field>
        <v-btn
          class="me-1"
          type="submit"
          color="primary"
          rounded="xl"
          size="large"
          flat
          :loading="isLoading"
        > Send Reset Email </v-btn>
        <v-btn
          class="mt-8 me-1"
          variant="text"
          prepend-icon="mdi-login"
          :to="{ name: 'Login' }"
        > Login </v-btn>
      </v-form>
    </v-sheet>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { sendPasswordResetEmail, getAuth } from 'firebase/auth'
import { appBarStore } from '@/store/AppBarStore.js'

const isLoading = ref(false);
const isError = ref(false)
const email = ref('')
const errorMessage = ref('')

appBarStore.value.update(
  'Reset Password',
  [
    {
      label: 'Register',
      icon: 'mdi-list-box-outline',
      routeName: 'Register'
    }
  ]
)

const sendResetEmail = () => {
  if (!email.value) {
    errorMessage.value = 'Please enter your email'
      isError.value = true
    return
  }

  const actionCodeSettings = {
    url: window.location.origin + '/login',
    handleCodeInApp: false,
  }

  isLoading.value = true;
  sendPasswordResetEmail(getAuth(), email.value, actionCodeSettings)
    .then(() => {
      errorMessage.value = 'Password reset email sent!'
      isError.value = true
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage.value = 'Invalid email'
          break
        case 'auth/user-not-found':
          errorMessage.value = 'No account with that email was found'
          break
        default:
          errorMessage.value = 'Error sending password reset email'
          break
      }
      isError.value = true
    })
    .finally(() => {
      isLoading.value = false;
    });
}
</script>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>