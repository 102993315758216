import { ref } from 'vue'
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore'

export function useFirestoreChannelIsTypingUpdater(channelId, userId) {
  const error = ref(null)

  const db = getFirestore()
  let docRef = doc(db, 'channels', channelId, 'isTyping', userId)

  const startTyping = async () => {
    await setDoc(docRef, { 
      isTypingAt: serverTimestamp(),
      isTyping: true
    })
    .catch((err) => {
      error.value = err
    })
  }

  const stopTyping = async () => {
    await setDoc(docRef, {
      isTypingAt: null,
      isTyping: false
    })
    .catch((err) => {
      error.value = err
    })
  }

  return { startTyping, stopTyping, error }
}