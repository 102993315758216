import { ref } from 'vue'

export const appBarStore = ref({
  title: '',
  actionItems: [],
  setTitle(title) {
    this.title = title
  },
  setActionItems(actionItems) {
    this.actionItems = actionItems
  },
  update(title, actionItems) {
    this.setTitle(title)
    this.setActionItems(actionItems)
  },
  clear() {
    this.title = '',
    this.actionItems = []
  }
})