import { useStore } from 'vuex'
import { api } from '@/utils/api.js'

export default function useAdventurePreview() {
  const store = useStore()

  const previewAdventure = async (invitationCode) => {
    return api.adventure.preview(invitationCode)
      .then((response) => {
        const adventurePreview = response.data.adventure
        store.commit('setAdventurePreviewData', {
          ...adventurePreview,
          invitationCode: invitationCode
        })
      })
      .catch((error) => {
        console.log(error)
        switch (error.response.status) {
          case 410:
            error.displayMessage = "Invitation code has expired"
            break
          case 409:
            error.displayMessage = "Invitation code has already been used"
            break
          default:
            error.displayMessage = "Invalid invitation code"
        }
        throw error
      })
  }

  return {
    previewAdventure
  }
}