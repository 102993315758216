<script setup>
const props = defineProps({
  errorMessage: {
    type: String,
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});
const invitationCode = defineModel('invitationCode')
</script>

<template>
  <div class="mt-3 mb-6">
    Enter the invitation code that was shared with you
  </div>
  <v-sheet width="300" class="mx-auto mt-4">
    <v-text-field
      label="Invitation Code"
      v-model="invitationCode"
      :disabled="props.isDisabled"
      :error-messages="props.errorMessage"
      class="text-case-sensitive code-font mt-3"
      hint="Invitation codes are case sensitive"
    ></v-text-field>
  </v-sheet>
</template>