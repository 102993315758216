<script setup>
import { ref, watch, onUnmounted, onMounted } from 'vue';
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import { useFirestoreProfile } from '@/composables/useFirestoreProfile.js'
import { getFirestoreProfileSession } from '@/composables/useFirestoreProfileSession'
import { api } from '@/utils/api.js';

const store = useStore()

const isLoading = ref(true)
const isEditingDisplayName = ref(false)
const displayName = ref('')
const isError = ref(false)
const errorMessage = ref('')
const profileData = ref([
  { label: 'Display Name', value: 'Temp' },
  { label: 'Joined', value: 'January 1, 2021'},
  { label: 'Available Turns', value: 0},
  { label: 'Turn Refresh Date', value: 'Unavailable'},
])
const index = {
  displayName: 0,
  joined: 1,
  availableTurns: 2,
  refreshDate: 3,
}

let profile, error, unsubscribe, stopWatch
if (store.state.user) {
  // eslint-disable-next-line no-unused-vars
  ({ profile, error, unsubscribe } = useFirestoreProfile(store.state.user))

  stopWatch = watch(profile, (newProfile) => {
    if (newProfile) {
      let createdAtDate = newProfile.createdAt.toDate(); 
      profileData.value[index.displayName].value = newProfile.displayName
      profileData.value[index.joined].value = createdAtDate.toLocaleDateString()
      isLoading.value = false
      unsubscribe()
    }
  }, { immediate: true });
}

onMounted(async () => {
  if (!store.state.user) return;

  const { session, error } = await getFirestoreProfileSession(store.state.user)
  if (session.value) {
    if (session.value.invocationBalance) {
      profileData.value[index.availableTurns].value = session.value.invocationBalance
    }
    if (session.value.invocationRefreshDate) {
      let refreshDate = session.value.invocationRefreshDate.toDate(); 
      profileData.value[index.refreshDate].value = refreshDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/New_York',
        timeZoneName: 'short'
      });
    }
  }
  if (error.value) {
    console.log(error.value)
  }
})

onUnmounted(() => {
  if (stopWatch) { stopWatch() }
  if (unsubscribe) { unsubscribe() }
})

appBarStore.value.setTitle('Profile')

const updateDisplayName = () => {
  isLoading.value = true
  api.profile.update(displayName.value)
      .then(() => {
        profileData.value = profileData.value.map(item => {
          if (item.label === 'Display Name') {
            return { ...item, value: displayName.value }
          }
          return item
        })
        isEditingDisplayName.value = false
      })
      .catch((error) => {
        console.log(error);
        isError.value = true
        switch (error.response.status) {
          default:
            errorMessage.value = "Unable to update profile";
        }
      })
      .finally(() => {
        isLoading.value = false;
      });

  // Update the display name in Firestore
  // ...
  // Update the display name in the local state
  // profileData.value = profileData.value.map(item => {
  //   if (item.label === 'Display Name') {
  //     return { ...item, value: displayName.value }
  //   }
  //   return item
  // })
  // isEditingDisplayName.value = false
}
</script>

<template>
  <v-card max-width="800" class="mx-auto mt-4 text-start card-primary">
    <v-card-title class="d-flex">
      <h2 class="text-h5">User Profile</h2>
      <v-spacer></v-spacer> <!-- This will push the button to the right -->
      <v-btn
        small
        icon
        :disabled="isLoading"
        @click="isEditingDisplayName = !isEditingDisplayName"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-sheet
      class="mx-auto mt-16 mb-16 text-center"
      max-width="300"
      max-height="200"
      v-if="isLoading"
    >
      <v-progress-circular indeterminate></v-progress-circular>
      <p>Updating profile...</p>
    </v-sheet>

    <v-list lines="two" v-if="!isLoading">
      <v-list-item
        v-for="item in profileData"
        :key="item.label"
      >
        <v-list-item-title class="list-title">
          <v-text-field
            v-if="item.label === 'Display Name' && isEditingDisplayName"
            v-model="displayName"
            :placeholder="item.value"
            @keyup.enter="updateDisplayName"
          />
          <template v-else>
            {{ item.value }}
          </template>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.label }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-card-actions v-if="isEditingDisplayName">
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="isEditingDisplayName = false">Cancel</v-btn>
      <v-btn color="primary" @click="updateDisplayName">Save</v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

.list-title {
  color: #333333;
  font-weight: 500;
  }
</style>