<script setup>
const props = defineProps({
  minHeight: {
    type: String,
    default: "250"
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  loadingMessage: {
    type: String,
    default: "Processing..."
  }
})
</script>

<template>
  <v-sheet :min-height="minHeight">
    <slot></slot>
    <div v-if="props.isLoading" class="mt-6">
      <v-progress-circular indeterminate></v-progress-circular>
      <p>{{props.loadingMessage}}</p>
    </div>
  </v-sheet>
</template>
