<script setup>
const props = defineProps({
  errorMessage: {
    type: String,
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});
const characterName = defineModel('characterName')
</script>

<template>
  <div class="mb-6">
    Choose a name for your character
  </div>
  <v-text-field
    v-model="characterName"
    label="Your Character Name"
    :disabled="props.isDisabled"
    :error-messages="errorMessage"
  ></v-text-field>
</template>