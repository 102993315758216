<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1>Start an Adventure</h1><br>
    <v-sheet width="300" class="mx-auto">
      <AdventureJoinCharacter 
        v-model:characterName="characterName"
        :isDisabled="isLoading"
      />    
      <v-btn
        class="me-4"
        type="submit"
        color="primary"
        rounded="xl"
        size="large"
        flat
        @click="startAdventure"
        :loading="isLoading"
      > Begin </v-btn>
    </v-sheet>
    <v-sheet class="mx-auto create-processing">
      <AdventureCreateProcessing
      :hidden="!isLoading"
      :isAnimationRunning="isLoading"
      />
    </v-sheet>
  </v-card>


  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { appBarStore } from '@/store/AppBarStore.js'
import { api } from '@/utils/api.js';
import AdventureJoinCharacter from '@/components/AdventureJoinCharacter.vue'
import AdventureCreateProcessing from '@/components/AdventureCreateProcessing.vue'

const store = useStore()
const router = useRouter()
const isLoading = ref(false);
const isError = ref(false);
const errorMessage = ref('')
const characterName = ref()

// TODO: action does not work with current routing setup
appBarStore.value.update(
  'Adventure',
  [
    {
      label: store.adventureId ? 'Active Adventure' : null,
      icon: store.adventureId ? 'mdi-heart-half-full' : null,
      routePath: store.adventureId ? `/adventure/${store.adventureID}` : null
    }
  ]
)

const startAdventure = async () => {
  if (!characterName.value) {
    errorMessage.value = "Character name cannot be empty"
    isError.value = true
    return
  }
  isLoading.value = true;
  api.adventure.create(characterName.value)
      .then((response) => {
        const adventureId = response.data.adventureId
        store.dispatch('setAdventureId', adventureId)
        store.dispatch('setAdventureName', characterName.value)
        router.push({
          name: 'Adventure',
          params: {
            id: adventureId
          }
        })
      })
      .catch((error) => {
        console.log(error);
        isError.value = true
        switch (error.response.status) {
          case 401:
            errorMessage.value = "Unauthorized. Try logging in again";
            break;
          default:
            errorMessage.value = "Network Error"
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
}

</script>

<script>
export default {
  name: 'StartAdventure',
}
</script>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>