<template>
  <v-card
    class="mx-auto mt-4 card-primary"
    max-width="800"
  >
    <h2>Adventures</h2>
    <v-list lines="two" class="text-left">
      <v-list-item
        v-for="adventure in adventures"
        :key="adventure.id"
        :to="adventureRoute(adventure.id)"
      >
        <template v-slot:prepend>
          <v-avatar color="grey-lighten-1">
            <v-icon color="white">mdi-book-open-page-variant</v-icon>
          </v-avatar>
        </template>
        <v-list-item-title>{{ adventure.name }}</v-list-item-title>
        <v-list-item-subtitle>
          Members: 
          <span>
            {{ adventure.members.map(member => adventure.displayNames[member]).join(', ') }}
          </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Created: {{ formatTimestamp(adventure.createdAt) }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

  </v-card>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import { query, where, orderBy, getFirestore, collection, getDocs } from 'firebase/firestore'
appBarStore.value.update( 'Adventure', [{
  label: 'Start New Adventure',
  icon: 'mdi-map-plus',
  routeName: 'AdventureCreate'
}])

const store = useStore()
const adventures = ref([])
const formatTimestamp = (timestamp) => {
  if (timestamp) {
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: '2-digit' 
    }).format(date);
  }
}
const adventureRoute = (adventureId) => {
  const route = { 
    name: 'Adventure', 
    params: { 
      id: adventureId
   }
  }
  return route
}

async function getChannels() {
  const db = getFirestore();
  const channelsRef = collection(db, 'channels');
  const q = query(
    channelsRef,
    where('members', 'array-contains', store.state.user),
    orderBy('createdAt', 'desc')
  );

  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}
getChannels()
.then(channels => {
  adventures.value = channels
})

</script>
