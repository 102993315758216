<script setup>
import {watch, nextTick, ref, toRaw, onMounted} from 'vue'
import Message from '@/components/AdventureChatMessage.vue'

const props = defineProps({
  messages: Array,
  displayNames: Object,
  playerNames: Object,
  isBotResponding: Boolean
})

const messagesContainer = ref(null)

const scrollToBottom = () => {
  nextTick(() => {
    const container = messagesContainer.value
    if (container) {
      container.scrollTop = container.scrollHeight
    }
  })
}

onMounted(scrollToBottom)

watch(() => toRaw(props.messages), scrollToBottom, { deep: true })

</script>

<template>
  <div class="message-area" ref="messagesContainer">
    <Message 
      v-for="(message, index) in messages" 
      :key="index" 
      :message="message"
      :displayNames="displayNames"
      :playerNames="playerNames"
    />
    <div v-if="isBotResponding" class="typing-indicator">
      <span class="dot-ellipsis dot-ellipsis-3"></span>
    </div>
  </div>
</template>

<style scoped>
.message-area {
  overflow-y: auto;
  flex-grow: 1;
}

/* animaged ellipsis follows */
.dot-ellipsis {
  display: inline-block;
  position: relative;
  height: 0.5em;
  margin-left: 0.2em;
  margin-bottom: 20px;
}

.dot-ellipsis::before,
.dot-ellipsis::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: currentColor;
}

.dot-ellipsis::before {
  left: 0.6em;
  animation: dot-ellipsis 1s infinite;
  animation-delay: 0.2s;
}

.dot-ellipsis::after {
  left: 1.2em;
  animation: dot-ellipsis 1s infinite;
  animation-delay: 0.4s;
}

@keyframes dot-ellipsis {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>