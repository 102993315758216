import { ref } from 'vue';
import { getFirestore, collection, query, onSnapshot, orderBy, limit } from 'firebase/firestore';

export function useFirestoreMessages(channelId, queryConditions) {
  const documents = ref([]);
  const error = ref(null);
  const db = getFirestore();

  let collectionRef = collection(db, 'channels', channelId, 'messages');
  let q = query(collectionRef, orderBy('createdAt', 'desc'), limit(20));
  
  if (queryConditions) {
    q = query(collectionRef, ...queryConditions);
  }

  const stop = onSnapshot(q, (snapshot) => {
    let results = [];
    snapshot.docs.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id });
    });
    documents.value = results.reverse();
    error.value = null;
  }, (err) => {
    console.log(err.message);
    documents.value = null;
    error.value = 'Could not fetch data';
  });

  return { documents, error, stop };
}
