<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'
import { useRoute, useRouter } from 'vue-router'
import AdventureChat from '@/components/AdventureChat.vue'
import AdventureChatSessionDrawer from '@/components/AdventureChatSessionDrawer.vue'
import { getFirestoreProfileSession } from '@/composables/useFirestoreProfileSession'

const store = useStore()
const router = useRouter()
const route = useRoute()

let isSessionDrawerOpen = ref(true)

const multiplayerAction = {
  label: 'Game Info',
  icon: 'mdi-gamepad-variant',
  handleClick: () => {
    isSessionDrawerOpen.value = !isSessionDrawerOpen.value
  },
}

const updateAppBar = () => {
  appBarStore.value.update('Adventure', [multiplayerAction])
}

updateAppBar()
watch(route, updateAppBar)

const adventureId = route.params.id

if (adventureId) {
  store.dispatch('setAdventureId', adventureId)
} else {
  if (!store.state.adventureId) {
    const { session, error } = await getFirestoreProfileSession(store.state.user)
    if (session.value && session.value.activeAdventureId) {
      store.dispatch('setAdventureId', session.value.activeAdventureId)
    } else if (error.value) {
      console.error("Error fetching session:", error.value)
    } 
  }

  if (store.state.adventureId) {
    router.push({
      name: 'Adventure',
      params: {
        id: store.state.adventureId
      }
    })
  } else {
    router.push({name: 'AdventureCreate'})
  }
}

</script>

<template>
  <AdventureChatSessionDrawer :isOpen="isSessionDrawerOpen" />
  <AdventureChat />
</template>
