import axios from 'axios'

export const configureApi = (token) => {
  axios.defaults.baseURL = process.env.VUE_APP_ADVENTURAWR_API_URL
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const adventure = {

  create: async (characterName) => {
    return axios.post('/adventure/create', {
      characterName: characterName
    })
  },

  invite: async (adventureId) => {
    return axios.post('/adventure/invite', { 
      adventureId: adventureId
    })
  },

  preview: async (invitationCode) => {
    return axios.post('/adventure/preview', { 
      invitationCode: invitationCode
    })
  },

  join: async (invitationCode, characterName) => {
    return axios.post('/adventure/join', { 
      invitationCode: invitationCode,
      characterName: characterName
    })
  },
  
  resume: async (adventureId) => {
    return axios.post('/adventure/resume', { 
      adventureId: adventureId
    })
  },

  prompt: async (adventureId, message) => {
    return axios.post('/adventure/prompt', { 
      adventureId: adventureId,
      input: message
    })
  },

  feedback: async (feedbackId, score) => {
    return axios.post('/adventure/feedback', { 
      feedbackId: feedbackId,
      score: score
    })
  }
}

const profile = {

  update: async (displayName) => {
    return axios.post('/profile/update', {
      displayName: displayName
    })
  },
}

export const api = {
  adventure: adventure,
  profile: profile
}

export default api
