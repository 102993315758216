<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card class="mx-auto mt-4 card-primary">
    <h1 class="mt-4 mb-6">Create an Account</h1>
    <v-sheet width="300" class="mx-auto">
      <v-form @submit.prevent="register">
        <v-text-field
          v-model="email"
          label="Email"
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="password"
          label="Password"
        ></v-text-field>
        <v-btn
          class="me-4"
          type="submit"
          color="primary"
          rounded="xl"
          size="large"
          flat
          :loading="isLoading"
        > Submit </v-btn>
      </v-form>
    </v-sheet>
  </v-card>

  <v-dialog id="errorDialog"
    v-model="isError"
    width="300"
  >
    <v-card>
      <v-card-text>
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="isError = false">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router' // import router
import { appBarStore } from '@/store/AppBarStore.js'

const isLoading = ref(false);
const isError = ref(false);
const email = ref('')
const password = ref('')
const errorMessage = ref('')

const store = useStore()
const router = useRouter() // get a reference to our vue router

appBarStore.value.update(
  'Register',
  [
    {
      label: 'Login',
      icon: 'mdi-login',
      routeName: 'Login'
    }
  ]
)

const register = () => {
  isLoading.value = true;
  createUserWithEmailAndPassword(getAuth(),email.value, password.value) // need .value because ref()
  .then((data) => {
    store.dispatch('setUser', data.user.uid)
    store.dispatch('setToken', data.user.accessToken)
    router.push('/pending') // redirect to the feed
  })
  .catch(error => {
    console.log(error)
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage.value = 'Email address already registered'
        break
      case 'auth/invalid-email':
        errorMessage.value = 'Invalid email'
        break
      case 'auth/operation-not-allowed':
        errorMessage.value = 'Email registration disabled'
        break  
      case 'auth/weak-password':
        errorMessage.value = 'Password not strong enough'
        break  
      case 'auth/missing-password':
        errorMessage.value = 'Password required'
        break  
      default:
        errorMessage.value = 'Unable to process registration'
        break
    }
    isError.value = true
  })
  .finally(() => {
    isLoading.value = false
  });
}
</script>

<style scoped>
#errorDialog {
  text-align: center;
}
</style>