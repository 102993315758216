<script setup>
import { ref, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useFirestoreChannel } from '@/composables/useFirestoreChannel'
import InviteDialog from '@/components/InviteDialog.vue'

const store = useStore()
const channelId = store.state.adventureId
const { document, stop } = useFirestoreChannel(channelId)

const players = computed(() => {
  const members = document.value?.members || []
  const displayNames = document.value?.displayNames || {}
  const playerNames = document.value?.playerNames || {}

  return members.map(memberId => ({
    memberId,
    displayName: displayNames[memberId] || '',
    playerName: playerNames[memberId] || ''
  }))
})

let inviteDialog = ref(null)

onUnmounted(() => {
  stop()
})
</script>

<template>
  <InviteDialog ref="inviteDialog" />

  <v-card color="accent">
    <v-card-title>
      Multiplayer
    </v-card-title>
    <v-card-text>
      <v-list density="compact" nav>
        <v-list-item
          v-for="(player, index) in players"
          :key="index"
          class="text-wrap"
        >
          {{ `${player.displayName} (${player.playerName})` }}
        </v-list-item>
        <v-list-item
          :title="'Invite Players'"
          :prepend-icon="'mdi-account-multiple-plus'"
          @click="inviteDialog.openDialog()"
        />
      </v-list>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.text-wrap {
  font-size: 13px;
  white-space: normal;
  word-wrap: break-word;
}
</style>