import { ref } from 'vue'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'

export function useFirestoreProfile(userId) {
  const profile = ref(null)
  const error = ref(null)

  const db = getFirestore()
  let docRef = doc(db, 'profiles', userId)

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      profile.value = { ...docSnap.data(), id: docSnap.id };
      error.value = null;
    } else {
      console.log("No such document!");
    }
  }, (err) => {
    console.log("Error fetching document:", err);
    error.value = err;
  })

  // Return the profile, error, and unsubscribe function
  return { profile, error, unsubscribe }
}