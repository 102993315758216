<script setup>
import { onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useFirestoreChannel } from '@/composables/useFirestoreChannel'

const store = useStore()
const channelId = store.state.adventureId
const { document, stop } = useFirestoreChannel(channelId)

// eslint-disable-next-line no-redeclare
const QuestStatus = {
  Available: "available",
  Active: "active",
  Inactive: "inactive",
  Complete: "complete",
}

const session = computed(() => {
  const sessionData = document.value?.session || { currentObjective: null, objectives: [] }

  const quests = sessionData.quests || [];
  const updatedQuests = quests.map(quest => {
    const reversedObjectives = quest.objectives.slice().reverse()
    const newCurrentObjective = quest.objectives.length - 1 - quest.currentObjective
    return {
      ...quest,
      objectives: reversedObjectives,
      currentObjective: newCurrentObjective
    }
  })
  // Sort quests by status: active, available, inactive
  updatedQuests.sort((a, b) => {
    const statusOrder = {
      [QuestStatus.Active]: 1,
      [QuestStatus.Available]: 2,
      [QuestStatus.Inactive]: 3,
    }
    return statusOrder[a.status] - statusOrder[b.status]
  })

  // Always display one objective (at current index) for any additional quests
  for (let i = 1; i < updatedQuests.length; i++) {
    const quest = updatedQuests[i];
    const currentObjective = Math.max(0, quest.currentObjective)
    updatedQuests[i] = {
      ...quest,
      objectives: [quest.objectives[currentObjective]],
      currentObjective: 0
    }
  }

  return {
    ...sessionData,
    quests: updatedQuests
  }
})

onUnmounted(() => {
  stop()
})
</script>

<template>
  <v-card color="accent">
    <v-card-title>
      Quests
    </v-card-title>
    <v-card-text>
      <div v-if="session.quests.length === 0">
        Enjoy yourself!! Something will go sideways soon enough...
      </div>
      <div v-else>
        <div v-for="(quest, questIndex) in session.quests" :key="questIndex">
          {{ quest.name }}
          <v-list density="compact">
            <v-list-item
              v-for="(objective, index) in quest.objectives"
              :key="index"
              :class="{ 
                'bg-primary': index === quest.currentObjective && quest.status === QuestStatus.Active,
                'inactive': index !== quest.currentObjective || quest.status !== QuestStatus.Active,
                'complete': index !== quest.currentObjective,
                'rounded': true
              }"
            >
              <v-list-item-title :class="{ 'current': index === quest.currentObjective && quest.status === QuestStatus.Active }">
                {{ objective }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-list-item-title {
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
}

.current {
  font-weight: 500;
}

.inactive {
  color: #AAAAAA; /* Adjust the color as needed */
}

.complete {
  text-decoration: line-through;
}
</style>


<script>
// Test cases here... need to be moved to script setup to be accessible in template
const QuestStatus = {
  Available: "available",
  Active: "active",
  Inactive: "inactive",
  Complete: "complete",
}

const createQuest = (name, status, objectives) => ({
  name,
  status,
  currentObjective: 0,
  objectives: objectives.slice().reverse(),
})

const quest1 = createQuest("The Melody of the Stars", QuestStatus.Active, [
  "Investigate the lakeside for clues",
  "Explore the hidden underwater cave",
  "Gather information about the singing stars",
])

const quest2 = createQuest("The Harmony Crystal", QuestStatus.Active, [
  "Gather information about the stolen Harmony Crystal",
  "Find clues about the thief’s whereabouts",
  "Gather information about the singing stars",
])

const sortQuests = (quests) => {
  const statusOrder = {
    [QuestStatus.Available]: 1,
    [QuestStatus.Active]: 2,
    [QuestStatus.Inactive]: 3,
  }
  return quests.sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
}

const getQuestsByState = (questState) => {
  let quests = []
  switch (questState) {
    case 0:
      quests = []
      break
    case 1:
      quest1.objectives = quest1.objectives.slice(0, 1)
      quest1.status = QuestStatus.Available
      quests = [quest1]
      break
    case 2:
      quest1.objectives = quest1.objectives.slice(0, 1)
      quest1.status = QuestStatus.Active
      quests = [quest1]
      break
    case 3:
      quest1.objectives = quest1.objectives.slice(0, 1)
      quest1.status = QuestStatus.Inactive

      quest2.objectives = quest2.objectives.slice(0, 1)
      quest2.status = QuestStatus.Active
      quests = [quest1, quest2]
      break
    case 4:
      quest1.objectives = quest1.objectives.slice(0, 1)
      quest1.status = QuestStatus.Inactive

      quest2.objectives = quest2.objectives.slice(0, 1)
      quest2.status = QuestStatus.Available
      quests = [quest1, quest2]
      break
    case 5:
      quest1.objectives = quest1.objectives.slice(0, 1)
      quest1.status = QuestStatus.Inactive

      quests = [quest1, quest2]
      break
  }
  return sortQuests(quests)
}

const questState = 3
// eslint-disable-next-line no-unused-vars
const quests = getQuestsByState(questState)

</script>