<script setup>
import { ref, watch } from 'vue'
import AdventureChatQuestList from './AdventureChatQuestList.vue';
import AdventureChatMultiplayer from './AdventureChatMultiplayer.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: null
  }
})

const sessionDrawer = ref(props.isOpen)

watch(() => props.isOpen, (newVal) => {
  sessionDrawer.value = newVal
})

</script>

<template>
  <v-navigation-drawer
    app
    v-model="sessionDrawer"
    class="text-left"
    location="right"
    color="tertiary"
  >
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <AdventureChatQuestList />
        </v-col>

        <v-col cols="12">
          <AdventureChatMultiplayer />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<style scoped>
/* Add any custom styles here */
</style>