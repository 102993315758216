<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card class="mx-auto mt-4 card-primary">
    <div>
      <h1 class="mt-4 mb-4"> Authorization Pending </h1>
      <p class="mb-4 px-8"> Your account has been successfully registered. An admin will need to authorize your account for our closed alpha before you can start an adventure. </p>
    </div>

    <div class="px-4 my-12">
      <img class="title-image" :src="require('@/assets/pending.png')" alt="Access Pending">
    </div>
  </v-card>
</template>

<script setup>
import { useRouter } from 'vue-router' // import router
import { useStore } from 'vuex'
import { appBarStore } from '@/store/AppBarStore.js'

appBarStore.value.setTitle('Pending');

const router = useRouter()
const store = useStore()
if (store.state.token === null) {
  router.push('/')
}

</script>

<style scoped>

/* CSS for mobile screens */
@media screen and (max-width: 768px) {
  .title-image {
    width: 100%;
    height: auto;
    max-height: 60vh;
  } 
}
</style>
