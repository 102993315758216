import { ref } from 'vue'
import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore'

export function useFirestoreProfileSession(userId) {
  const session = ref(null)
  const error = ref(null)

  const db = getFirestore()
  let docRef = doc(db, 'profiles', userId, 'privateData', 'session')

  const stop = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      session.value = { ...docSnap.data(), id: docSnap.id };
      error.value = null;
    } else {
      console.log("No such document!");
    }
  }, (err) => {
    console.log("Error fetching document:", err);
    error.value = err;
  })

  return { session, error, stop }
}

export async function getFirestoreProfileSession(userId) {
  const session = ref(null)
  const error = ref(null)

  const db = getFirestore()
  let docRef = doc(db, 'profiles', userId, 'privateData', 'session')

  try {
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      session.value = { ...docSnap.data(), id: docSnap.id };
      error.value = null;
    } else {
      console.log("No such document!");
    }
  } catch (err) {
    console.log("Error fetching document:", err);
    error.value = err;
  }

  return { session, error }
}
