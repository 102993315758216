import { ref, reactive } from 'vue';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

export function useFirestoreChannelIsTypingWatcher(channelId) {
  const isTyping = reactive({});
  const error = ref(null);

  const db = getFirestore();
  let colRef = collection(db, 'channels', channelId, 'isTyping');

  const stop = onSnapshot(colRef, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      isTyping[doc.id] = {
        isTypingAt: data.isTypingAt,
        isTyping: data.isTyping
      };
    });
  }, (err) => {
    console.log("Error fetching documents:", err);
    error.value = err;
  });

  return { isTyping, error, stop };
}